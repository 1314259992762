@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,700';
@import "../template/mixin_templates";

/* Variables */
$largeur_fenetre_grand: 1360px;
$largeur_contenu: $largeur_fenetre_grand - 310px;

/*$small-screen: 640px;
$medium-screen: 1024px;
$large-screen: 1200px;
$xlarge-screen: 1440px;*/

/***************************************************/
/******************** ANIMATIONS *******************/
/***************************************************/

@keyframes menu-anim {
  0% {
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

/***************************************************/
/********************* GENERAL *********************/
/***************************************************/

body, html {
  font-size: 16px;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 300;
  margin: 0;
  box-sizing: border-box;
}

strong, b {
  font-weight: 700;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@include titreBaseSize();

h1, .h1 {
  font-size: 21px;
  font-weight: bold;
}

h2, .h2, h1, .h1 {
  margin: 15px 0;
  font-weight: bold;
  font-size: 24px;
  padding: 10px;
  position: relative;
  display: block;
  line-height: 20px;
}

a {
  text-decoration: none;
  img {
    border: none;
  }
}

img:not(.img-logo) {
  max-width: 100%;
  height: auto !important;
}

/* Permet de bien afficher le hover des images fiches produits pour le zoom */
.content_p .zoomPupImgWrapper > img, .content_p .zoomWrapperImage > img {
  max-width: none;
}

ul {
  padding: 0;
  list-style: none;
  list-style-position: inside;
}

iframe {
  max-width: 100%;
}

table {
  position: relative;
  table-layout: fixed;
  max-width: 100% !important;
}

.clearfix, .clear {
  clear: both;
}

button:hover {
  cursor: pointer;
}

.button, .bouton, .produits .mask .zoom {
  letter-spacing: 1px;
  width: auto;
  display: inline-block;
  padding: 11px 13px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all .2s linear;
  margin: 10px 10px;
}

.button {
  border: 1px solid;
}

/* Conteneur principal */

#main-conteneur {
  margin: 0 auto;
  position: relative;
  padding: 0;
  box-sizing: border-box;
  min-height: 100%;
  overflow: hidden;
}

#headercontent {
  width: $largeur_contenu;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
}

#wrapper, footer {
  width: $largeur_contenu;
  margin: 0 auto;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

#wrapper.content_p {
  padding-bottom: 60px;
  &::after {
    content: '';
    clear: both;
    display: block;
  }
}

.content_p, #wrapper {
  position: relative;
}

/* site en deux colonnes */
aside.sidebar {
  display: none;
}

/**************************************************/
/********************* HEADER *********************/
/**************************************************/

header {
  padding: 0;
  z-index: 1050;
  position: relative;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  #blocContent {
    z-index: 3;
  }
}

.logo {
  display: inline-block;
  position: absolute;
  left: 45%;
}

.logo img {
  max-height: 155px;
  max-width: 100%;
  height: 120px;
}

#headercontent {
  text-align: center;
  height: 155px;

  div.container-slogan {
    display: inline-block;
    width: 100%;
  }
}

h1.slogan,
.h1.slogan {
  margin: 0 auto;
}

.slogan {
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center;
  margin: 0;
}

.btn-search {
  margin: 0 !important;
}

#bloc-fil-title {
  position: relative;
  text-align: center;
  box-sizing: content-box;
  width: 100%;
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  div.clearfix {
    display: none;
  }

}

.cmonsite-ficheproduit {
  h1.title_section {
    display: none;
  }
}

h1.title_section, .titre_page h1, .titre_page .h1 {
  box-sizing: border-box;
  width: 100%;
  text-transform: uppercase;
  border-width: 0;
  font-size: 30px;
  font-weight: bold;
  &::before {
    display: none;
  }
}

/************************************************/
/********************* MENU *********************/
/************************************************/

/* fil d'Arianne */

.arianne {
  z-index: 3;
  position: relative;
  display: block;
  margin: 15px auto;
  text-align: center;
  ul {
    display: inline-block;
  }
  li {
    text-transform: uppercase;
    &:not(:first-child)::before {
      content: '\f0da';
      display: inline-block;
      margin: 0 10px;
      font-family: fontAwesome, sans-serif;
      font-size: 11px;
    }
    a {
      font-weight: bold;
      font-size: 11px;
    }

  }
}

#nav-principal:empty, #nav-secondary:empty {
  display: none;
}

#nav-principal {
  position: relative;
  z-index: 5;
  padding: 0;
  margin: 0 auto;
  display: block;
  max-width: 1050px;
  width: 100%;
  ul {
    text-align: center;
    font-size: 0;
    ul {
      position: absolute;
      text-align: left;
      top: 0;
      margin-top: 40px;
      border-top: 2px solid;
      height: auto;
      left: 0;
      z-index: 1;
      opacity: 0;
      display: none;
      transition: opacity .3s ease-in-out;
      animation: menu-anim .3s ease-in-out;

      li > ul {
        margin-top: -2px;
      }
      li {
        display: block;
        overflow: hidden;
        padding: 5px 25px 5px 5px !important;
        height: auto;
        &:hover, &.sousmenu_ouvert {
          overflow: visible;
        }
      }
      a {
        padding-left: 20px;
      }
      ul {
        left: 100%;
      }
    }
  }
  li {
    display: inline-block;
    height: 40px;
    font-size: 1rem;
    position: relative;
    transition: all .2s linear;
    padding: 0;
    box-sizing: border-box;
    vertical-align: top;
    &.hasDropdown > a:after {
      content: "\f0d7";
      display: inline-block;
      width: 10px;
      margin-left: 15px;
      font-family: fontawesome, sans-serif;
    }
    &:hover >, a:focus + {
      ul {
        display: block;
        opacity: 1;
      }
    }
    a {
      line-height: 40px;
      height: 40px;
      display: block;
      font-size: 12px;
      white-space: nowrap;
      transition: all .2s linear;
      box-sizing: border-box;
      position: relative;
      padding: 0 10px;
      span {
        i {
          font-size: 15px;
          display: inline-block;
          margin: 0 10px !important;
        }
      }
    }
  }
  > ul li {
    //Si l'utilisateur a mis des pictos c'est eux qui ont le style
    &.has_picto > a > span > i {
      font-size: 15px;
      display: inline-block;
      margin: 0 10px 0 0 !important;
    }
    li.hasDropdown:hover > a, li.hasDropdown.sousmenu_ouvert > a {
      &::after {
        transform: rotate(-90deg);
      }
    }
  }
}

#nav-secondary {
  position: relative;
  padding: 0;
  margin: 0 auto;
  display: block;
  width: 1050px;
  ul {
    font-size: 0;
    text-align: center;
    ul {
      position: absolute;
      text-align: left;
      top: 0;
      margin-top: 55px;
      border-top: 2px solid;
      left: 0;
      z-index: 1;
      width: 100%;
      max-width: 210px;
      opacity: 0;
      display: none;
      transition: opacity .3s ease-in-out;
      animation: menu-anim .3s ease-in-out;
      li > ul {
        margin-top: -2px;
      }
      li {
        display: block;
        border-left: 1px solid;
        overflow: hidden;
        &:hover, &.sousmenu_ouvert {
          overflow: visible;
        }
      }
      a {
        padding-left: 20px;
      }
      ul {
        left: 100%;
      }
    }
  }

  li {
    display: inline-block;
    font-size: 1rem;
    position: relative;
    min-height: 55px;
    transition: all .2s linear;
    box-sizing: border-box;
    &.hasDropdown > a:after {
      content: "\f0d7";
      display: inline-block;
      width: 10px;
      margin-left: 15px;
      font-family: fontawesome, sans-serif;
      height: 54px;
      line-height: 55px;
      box-sizing: border-box;
    }
    &:last-child {
      min-height: 55px;
    }
    &:hover >, a:focus + {
      ul {
        display: block;
        opacity: 1;
      }
    }
    ul li a {
      line-height: 30px;
    }
  }
  a {
    width: 100%;
    display: block;
    padding: 0 20px;
    line-height: 55px;
    font-size: 16px;
    transition: all .2s linear;
    box-sizing: border-box;
    position: relative;
  }
  > ul > li {
    //Si l'utilisateur a mis des pictos c'est eux qui ont le style
    &.has_picto > a > span > i {
      height: 100%;
      position: relative;
      margin-top: 0;
      left: 0;
      line-height: 55px;
      display: inline-block;
      float: left;
      font-size: 25px;
      text-align: center;
      margin-right: 20px;
    }
    &:hover > a, > a:focus, &.actif > a {
      &::after {
        opacity: 1;
        left: 62px;
      }
    }
    ul li.hasDropdown:hover > a, li.hasDropdown.sousmenu_ouvert > a {
      &::after {
        transform: rotate(-90deg);
      }
    }
  }
}

.menu.navigation_p {
  font-weight: bold;
}

header .menu {
  position: relative;
  z-index: 1050;
}

.wrapper-content .menu {
  position: relative;
  z-index: 1049;
}

#nav-secondary {
  li.navigation_button_p {
    ul {
      min-width: 250px;
      width: auto;
      max-width: none;
    }
    .sous_navigation_button_p {
      min-height: 0;
      border: none;
      padding: 15px 30px;
      a {
        padding: 0;
        white-space: nowrap;
        font-size: 14px;

        &:hover {
        }
      }
      &.hasDropdown {
        ul li {
          padding: 0;
          white-space: nowrap;
          a {
            padding: 15px 20px;
            white-space: nowrap;

            :hover {
            }
          }
        }
        &:hover > a:first-child {
          &:after {
            transform: rotatez(-90deg);
          }
        }
        > a:first-child {
          padding: 0;
          white-space: nowrap;
        }
      }
    }
  }
}

/****************************************************/
/********************* PRODUITS *********************/
/****************************************************/

//Etiquettes

.produit_etiquette {
  right: 0;
  top: 0;
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
  position: absolute !important;
  border-radius: 0;
  font-size: 20px;
  text-align: center;
  padding: 0;
  overflow: hidden;
  max-width: 100% !important;
  left: auto;
  h3, .h3 {
    padding: 0;
    text-indent: -9999px;
  }
  &::before {
    content: attr(data-title);
    display: block;
    position: absolute;
    opacity: 0;
    right: -100%;
    font-size: 13px;
    font-weight: bold;
    line-height: 50px;
    height: 50px;
    padding: 0 35px 0 10px;
    top: 0;
    white-space: nowrap;
    transition: all .2s linear;
  }
  &::after {
    border-width: 0;
  }
  &:hover, &:focus {
    overflow: visible;
    &::before {
      right: 50%;
      opacity: 1;
    }
  }
  i {
    text-indent: 0;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    line-height: 50px;
    margin: 0 !important;
    &::before {
      content: "\f129";
      font-family: "fontAwesome", sans-serif;
      font-style: normal;
      font-weight: normal;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      margin-left: .2em;
    }
  }
}

h3 .fa.fa-bookmark, .h3 .fa.fa-bookmark {
  width: 50px;
  left: 5px;
  height: 50px;
  top: -5px;
  padding: 13px;
}

/*Produits*/

.produits {
  border: 1px solid rgba(0, 0, 0, .05);
  .remise {
    margin-top: 0;
    padding: 10px;
    font-weight: bold;
  }
  .nomprod {
    padding: 20px 0;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
  }
  .productImageWrap {
    position: relative;
    overflow: hidden;
  }
  .mask {
    position: absolute;
    width: 100%;
    a {
      opacity: 0;
    }

    a.zoom.produit_galerie_background_p.button.border_p {
      width: 100%;
      height: 100%;

      &::before {
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
  }
  a.zoom {
    opacity: 0;
    &::before {
      text-align: center;
    }
  }
  .oldprix {
    text-decoration: line-through;
    opacity: .7;
    margin: 0;
    position: static;
    line-height: 1;
    height: 17px;
    color: red;
  }
  .prixprod {
    margin: 0;
    line-height: 1.4;
    height: 24px;
    font-weight: bold;
  }
  &:hover, &:focus {
    .mask a {
      opacity: .5;
    }
    a.zoom {
      opacity: .5;
    }
  }
  .prix {
    margin-bottom: 20px;
    text-align: center;
    height: 55px !important;
    padding: 0;
    line-height: 53px;
    box-sizing: border-box;
    > span {
      font-weight: normal;
      display: block;
      text-align: center;
      padding: 0;
      width: 50%;
      flex: 0 1 auto;
    }
  }
  .desc {
    margin-bottom: 20px;
  }
  .remise ~ .prix, .remise ~ .productContentWrap .prix {
    border-right: 1px solid;
    height: 55px;
    line-height: normal;
    margin: 0 0 20px;
  }
  > .remise {
    display: none;
  }
  .remise {
    width: 50%;
    height: 55px;
    line-height: 55px;
    padding: 0;
    text-align: center;
    z-index: 1;
    span {
      font-size: 20px;
    }
  }
  .hors-stock {
    margin: 5px 0;
    border: none;
    text-transform: uppercase;
    font-size: 14px;
  }
  .productImageWrap {
    overflow: visible;
  }
  .productContentWrap {
    position: static;
  }
  .container-etiquette {
    position: static;
  }
  &:hover, &:focus {
    .mask, .addbasket {
      opacity: 1;
    }
  }
  .note-produit {
    text-align: center;

  }
}

.mode-boutique .icon-bookmark-empty {
  width: 50px;
}

.template_boutique_all .produits {
  float: left;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: background .2s linear;
  margin-bottom: 20px;
  padding: 0;
  .container-etiquette {
    position: static;
  }
  .desc {
    margin-bottom: 20px;
  }
  &.block_unique_product {
    a img.border_p {
      transition: all .2s linear;
    }
    &:hover a img.border_p {
      transform: scale(1.02);
      transition: all .2s linear;
    }
  }
  .attr, .attr select {
    height: 21px;
    margin: 10px auto 10px;
    display: block;
    float: none;
    position: static;
  }
  .prix {
    height: 55px;
    line-height: normal;
    margin: 0 0 20px;
    border-top: 1px solid currentcolor;
    border-bottom: 1px solid currentcolor;
  }
  .remise ~ .prix, .remise ~ .productContentWrap .prix {
    border: 1px solid;
    height: 55px;
    line-height: normal;
    margin: 0 -1px 20px;
  }
  .remise {
    margin-top: 0;
    font-weight: bold;
    width: 50%;
    height: 55px;
    line-height: 55px;
    padding: 0;
    text-align: center;
    z-index: 1;
    span {
      font-size: 20px;
    }
  }
  > .remise {
    display: none;
  }
  .nomprod {
    padding: 20px 0;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }
  .prix {
    margin-bottom: 20px;
    text-align: center;
    height: 55px !important; //Important oblige a cause du JS
    padding: 0;
    line-height: 18px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .oldprix {
      text-decoration: line-through;
      opacity: .7;
      margin: 0;
      position: static;
      line-height: 1;
      height: 17px;
      font-weight: normal;
    }
    .prixprod {
      margin: 0;
      line-height: 1.4;
      height: 24px;
    }
    > span {
      display: block;
      text-align: center;
      padding: 0;
      width: 50%;
      flex: 0 1 auto;
    }
    .remise {
      display: block !important; //Pour passer au-dessus de ce qui est ecrit dans la div
      font-weight: bold;
    }
  }
  .productImageWrap {
    position: relative;
    overflow: hidden;
    a img {
      transition: all .2s linear;
    }
    &:hover a img {
      transform: scale(1.02);
      transition: all .2s linear;
    }
    .zoom.produit_galerie_background_p {
      width: 100%;
      height: 100%;
      &::before {
        font-size: 45px;
        margin: 45% auto;
        top: 0;
      }
    }
  }
  .productContentWrap {
    position: static;
  }
  a.zoom {
    &::before {
      text-align: center;
    }
  }
  .mask, .zoom {
    position: absolute;
    height: 100%;
    width: 100%;
    line-height: 33px;
    text-align: center;
    top: 0;
    left: 0;
    transition: all .2s linear;
    padding: 0;
    margin: 0;
  }
  .zoom {
    opacity: 0;
    &::before {
      line-height: 32px;
      font-size: 45px;
      margin: 0;
      top: 0;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .mask {
    opacity: 1;
    background: transparent !important;
  }
  .block-btn-addbasket {
    margin: 0;
  }
  .hors-stock, .addbasket {
    margin: 5px 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
    left: 0;
    padding: 13px 15px;
    box-sizing: border-box;
  }
  .addbasket {
    i {
      width: 45px;
      text-align: center;
      height: 33px;
      line-height: 33px;
      position: absolute;
      left: 0;
      top: 0;
      text-indent: 0;
      display: none;
    }
  }
  .hors-stock {
    margin: 5px 0;
    border: none;
    text-transform: uppercase;
    font-size: 14px;
    height: auto;
  }

  &:hover, &:focus {
    .zoom {
      opacity: .5;
    }
    .addbasket {
      opacity: 1;
    }
    .productImageWrap a img {
      transform: scale(1.02);
      transition: transform 0.3s;
    }
  }
}

//Templates produits accueil

.template_boutique_accueil {
  .produits {
    .nomprod {
      font-weight: bold;
      margin: 0 auto 5px;
      padding: 0 5px;
    }
    .addbasket, .hors-stock {
      margin: 5px;
      width: auto;
      display: block;
      float: none;
    }
    .productImageWrap {
      text-align: center;
    }
  }
  &.accueil_boutique_template_1 {
    $largeurFleches: 45px;
    $margin: 20px;
    $nbrProduit: 4;
    @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);

    .bx-wrapper {
      margin: 0;
    }
    .bx-wrapper .bx-controls-direction a {
      width: 40px;
      height: 40px;
      line-height: 40px;
      &.bx-prev, &.bx-next {
        right: -50px;
        left: auto;
        top: 0;
        bottom: auto;
      }
      &.bx-prev {
        top: 50px;
      }
    }
  }
  &.accueil_boutique_template_1, &.accueil_boutique_template_2 {
    .produits {
      .nomprod {
        font-weight: bold;
        margin: 0 auto 5px;
      }
    }
  }
}

//Templates produits

.template_boutique {
  .produits {
    .productContentWrap {
      padding: 5px;
    }
    .remise ~ .prix, .remise ~ .productContentWrap .prix {
      margin: 0 -6px 20px;
    }
  }
  &.template_boutique_1 {
    .produits {
      .attr {
        width: auto;
        select {
          width: 140px
        }
      }
      .prix {
        width: 100%;
        width: calc(100% + 12px);
        margin: 0 -6px 20px;
      }
    }
    .bx-wrapper .bx-controls-direction a {
      width: 40px;
      height: 40px;
      right: -50px;
      line-height: 40px;
      top: 0;
      &.bx-prev {
        top: 50px;
      }
    }
  }

  &.template_boutique_2 {
    .produits {
      .prix {
        width: 100%;
        width: calc(100% + 12px);
        margin: 0 -6px 20px;
      }
    }
  }

  &.template_boutique_3 {
    .produits {
      width: 100%;
      .productContentWrap {
        width: 70%;
        font-size: 15px;
        margin-bottom: 10px;
      }
      p.desc {
        margin: 35px 20px;
      }
      .remise ~ .productContentWrap .prix, p.prix {
        margin: 0 20px;
      }
      .addbasket, .hors-stock .inside-hors-stock {
        position: relative;
        z-index: 10;
        width: 50%;
        font-size: 14px;
        margin: 5px auto;
        left: auto;
        display: inherit;
        text-align: center;
        .inside-addbasket {
          width: 100%;
        }
      }
      .prix {
        .prixprod {
          font-weight: bold;
          line-height: 20px;
          font-size: 24px;
          height: auto;
        }
        .oldprix {
          font-size: 17px;
          color: red;
        }
      }
      .remise {
        top: 0;
      }
      .nomprod {
        padding: 5px;
      }
      .addbasket {
        bottom: 0;
      }
      .remise {
        font-size: 22px;
      }
      &.is_etiquette .nomprod {
        margin-top: 0;
      }
      .container-etiquette .produit_etiquette {
        position: absolute;
      }
    }
  }
  &.template_boutique_4 {
    .produits {
      .produit_etiquette {
        max-width: none !important;
      }
      .prix {
        width: 100%;
        border-right-width: 0;
        margin-top: 10px;
        padding: 0;
        .prixprod {
          width: auto;;

        }
        .oldprix {
          padding-top: 5px;
          width: 50%;
        }
        .remise {
          width: 55%;
        }
      }
      .attr {
        padding: 0;
      }
      .attr select {
        position: static;
      }
      .container-etiquette {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
      }
      .productImageWrap .zoom.produit_galerie_background_p::before {
        margin: -16px auto 0;
      }
    }
  }
  &.template_boutique_5 {
    .produits {
      .container-etiquette {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0;
      }
      .produit_etiquette {
        &::before {
          right: auto;
          left: -100%;
          padding: 0 10px 0 35px;
        }
        &:hover::before, &:focus::before {
          right: auto;
          left: 50%;
          opacity: 1;
        }
      }
      .prix {
        clear: both;
        float: left;
        width: 100%;
        width: calc(100% + 12px);
        margin: 15px -6px;
      }
      .remise ~ .productContentWrap .prix {
        width: 100%;
        width: calc(100% + 12px);
        margin: 15px -6px;
      }
      .attr {
        padding: 0;
        clear: both;
        float: none;
      }
      .attr select {
        position: static;
      }
      .productImageWrap {
        min-height: 0;
        height: auto;
      }
    }
  }
}

/* Fiche produits */

.template_fiche_produit {
  #fiche-produit {
    .produit_etiquette, .produit_etiquette h3, .produit_etiquette .h3 {
      right: 0;
      top: 0;
      z-index: 1001;
    }
    .wrap-description .ajouter-panier .addbasket {
      font-size: 1rem;
      line-height: normal;
      padding: 10px;
      margin-top: 20px;
    }
  }
  &.template_fiche_produit_2 #fiche-produit {
    .wrap-description {
      > .row {
        margin: 0;
        .col.s12, .col.s8, .col.s4 {
          padding: 0;
        }
      }
      .fiche-titre-produit {
        margin-left: 0;
      }
    }
    .brand {
      padding: 10px 0;
      margin: 0;
    }
    .top_fiche .wrap-description .ajouter-panier .addbasket {
      font-size: 1rem;
      line-height: normal;
      padding: 10px;
      margin-top: 20px;
    }
    .container-etiquette {
      margin-bottom: 0 !important;
      .produit_etiquette {
        left: -50px;
      }
    }
  }
  &.template_fiche_produit_3 #fiche-produit {
    .brand {
      padding: 10px 0;
    }
  }
}

h3 .fa.fa-bookmark, .h3 .fa.fa-bookmark {
  width: 50px;
  left: 5px;
  height: 50px;
  top: -5px;
  padding: 13px;
}

/*********************************************************/
/********************* FICHE PRODUIT *********************/
/*********************************************************/

/* miniatures photos */

#fiche-produit {
  .top_fiche {
    margin-bottom: 30px;
    margin-top: 30px;
    .zoom-img-position {
      margin: 0;
    }
    .previews {
      position: relative;
      z-index: 15;
      padding: 15px;
      margin: 0;
      .previous, .next {
        height: 35px;
        line-height: 33px;
        width: 35px;
        display: block;
        text-indent: 0;
        right: 0;
        font-size: 20px;
        text-align: center;
        transition: all .2s linear;
        border: 2px solid;
      }
      .previous {
        position: relative;
        margin-right: 35px;
        i::before {
          content: '\f104';
        }
      }
      .next {
        float: right;
        position: absolute;
        margin-right: 15px;
        i::before {
          content: '\f105';
        }
      }
    }

    .wrap-images {
      margin-right: 0;

      .grande-image, .grande-image > a {
        height: auto;
      }
      .zoomPad {
        float: none;
      }
    }

    .wrap-description {
      padding-left: 40px;
      box-sizing: border-box;
      position: relative;

      .row .col.s12 h3,
      .row .col.s12 .h3,
      .row .col.s12 .h1 {
        max-width: 100%;
        padding: 15px;
        box-sizing: border-box;

      }

      .ajouter-panier {
        width: 50%;
        float: right;
        margin-bottom: 15px;
        box-sizing: border-box;
        position: relative;
        font-size: 0;
        text-align: center;
        vertical-align: middle;
        display: block;

        &.block-price {
          width: 100%;
          &.without-promotion {
            .prix {
              float: right;
            }
          }
        }

        &::before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          height: 100%;
        }
        &::after {
          content: "";
          display: block;
          clear: both;
          width: 100%;
        }
        > div, > p, > a {
          display: inline-block;
          vertical-align: middle;
          font-size: 1rem;
          float: none;
          overflow: visible;
          width: auto;
        }
        .prix {
          line-height: initial;
          padding: 0 15px;
          width: 50%;
          margin: 0 0 15px 0;
          height: 55px;
          text-align: center;
          float: left;
          display: block;
          border: none;
          .oldprix {
            line-height: 40px;
          }
          .prixprod {
            line-height: 40px;
            position: relative;
            top: 3px;
          }
          > span {
            display: inline-block;
            line-height: initial;
            width: auto;
          }
        }

        .triangle-ficheprod-second {
          position: relative;
          width: 50%;
          height: 55px;
          text-align: center;
          margin-bottom: 15px;
          float: right;

          ~ .prix {
            line-height: initial;
            padding: 0 15px;
            width: 50%;
            margin: 0 0 15px 0;
            height: 55px;
            text-align: center;
            border: 1px solid;
            .oldprix {
              line-height: 40px;
            }
            .prixprod {
              line-height: 40px;
              position: relative;
              top: 3px;
            }
            > span {
              display: inline-block;
              line-height: initial;
              width: auto;
            }
          }
        }
        .remise-produit {
          position: relative;
          right: auto;
          font-size: 30px;
          padding: 5px 15px;
          display: inline-block;
          height: auto;
          width: auto;
          border-radius: 0;
        }
        .addbasket {
          height: auto;
          width: 100%;
          float: left;
          line-height: normal;
          padding: 15px 13px;
          margin: 0;
          display: inline-block;
          text-align: center;
          border: 1px solid;
          .fa-shopping-cart {
            margin: 0 5px;
          }
          &:hover {
            border: 1px solid;
          }
        }
        .declinaison.row {
          text-align: left;
        }
      }
      p.stock {
        margin: 0;
        padding: 15px;
        text-align: center;
        font-weight: bold;
        font-size: 15px;
      }
      .details-ficheprod {
        text-align: center;
        font-size: 16px;
        margin: 30px 0;
        a {
          background: none;
          border: none;
        }
      }
      .declinaison {
        padding: 15px;
        .declinaison-attribute-group select, .declinaison-attribute-group input,
        .product_options-group select, .product_options-group input {
          padding: 5px;
        }
      }
      .bloc-quantite {
        text-transform: uppercase;
        margin-right: 0;

        .notification_product {
          margin: 15px auto 0;
          line-height: initial;
          color: red;
          font-size: .8rem;
          text-align: left;
        }
        .notification_product:empty {
          display: none;
        }
      }
      .block-fiche-titre-brand {
        margin-bottom: 10px;
        display: table;
        table-layout: fixed;
        .col.s8.block-fiche-titre {
          padding: 15px;
        }
        h3, .h3 {
          margin-bottom: 0;
        }
      }
      > span {
        margin: 15px;
      }
      p.note-produit {
        margin: 15px;
        .fa-star, .fa-star-o {
          margin: 0 2px;
        }
      }
      p.description {
        font-size: 16px;
        text-align: justify;
        display: block;
        clear: both;
        &::first-letter {
          font-size: 48px;
          float: left;
          margin: 9px 0 0;
        }
      }
      .sociaux {
        border-bottom: 1px solid #ccc;
        position: relative;
        float: left;
        width: 100%;
      }
      h3 span, .h3 span {
        font-size: 21px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .declinaison-attribute-group, .product_options-group {
        margin-bottom: 5px;
        label:first-child {
          text-transform: uppercase;
        }
      }
      .declinaison {
        .radio_color {
          box-shadow: none;
          transition: all .4s linear;
          border-color: rgba(0, 0, 0, .05);
          border-radius: 0;
          &:hover, &:focus, &.radio-color-checked {
            border-color: rgba(0, 0, 0, .2);
          }
        }
        .radio_unique {
          box-shadow: none;
          border: 1px solid;
          border-radius: 0;
          &:hover, &.radio-checked {
            cursor: pointer;
          }
        }
      }
      .declinaison label {
        &.radio_unique {
          float: none;
        }
        &:first-child {
          display: inline-block;
          margin-right: 10px;
        }
      }
      .container-etiquette {
        position: static;
      }
    }
    .message-wrap, #addcomment > div {
      box-shadow: none;
      border-color: rgba(0, 0, 0, .1);
    }
  }
}

.reservation__list > * {
  flex: 1 1 auto;
}

.wrap-details.bloc-description-detaille, .wrap-details.bloc-suggestions, .wrap-details.bloc-avis-clients {
  > h4, > .h4 {
    width: 100%;
    span {
      float: none;
      width: auto;
      line-height: 55px;
      height: 55px;
      padding: 0 15px;
      display: inline-block;
    }
  }
  .details {
    font-size: 16px;
  }
}

.triangle-ficheprod {
  position: absolute;
  right: 0;
  top: 0;
}

.template_fiche_produit {
  #fiche-produit {
    .remise-produit {
      display: none;
    }
    .ajouter-panier .remise-produit {
      display: block;
    }
  }
  &.template_fiche_produit_1 {
    #fiche-produit {
      .container-etiquette {
        margin-bottom: 0;
      }
      .top_fiche {
        display: table;
        table-layout: fixed;
        width: 100%;
        > div {
          vertical-align: top;
          float: none;
          display: table-cell;
        }
        .wrap-description {
          .block-fiche-titre-brand {
            margin-left: 0;
            margin-right: 0;
            .col.s12 {
              padding-right: 0;
              .h1 {
                max-width: none;
              }
            }
          }
        }
      }
      .triangle-ficheprod-second {
        display: block !important;
      }
      .wrap-description {
        h1, .h1 {
          margin: 0 0 0 -.5rem;
        }
      }
    }
  }
  &.template_fiche_produit_2 {
    #fiche-produit {
      .wrap-images {
        .triangle-ficheprod {
          display: none;
        }
        .grande-image {
          .sliderImageFicheProd {
            width: 80%;
          }
          #sliderImagePreviews {
            width: 20%;
            padding: 15px;
          }
        }
      }
      .wrap-description {
        h1, .h1 {
          margin: 0;
        }
        > .row {
          margin-top: 0;
        }
      }
      .wrap-images, #savoirplus {
        width: 60%;
        padding: 0;
        margin-bottom: 15px;
      }
      .wrap-description {
        h1, .h1 {
          text-align: center;
        }
        .bloc-quantite {
          label {
            font-size: 15px;
            margin-right: 5px;
          }
        }
      }
      .wrap-description {
        width: 40%;
        h1, .h1 {
          margin-top: 0;
          padding: 15px;
        }
        .declinaison.row {
          margin-bottom: 0;
          padding: 10px 0;
          .declinaison-attribute-group .radio_color, .product_options-group .radio_color {
            margin: 5px;
          }
        }
        > span {
          margin: 15px 0;
        }
        .note-produit {
          margin: 10px 0;
        }
        .bloc-quantite {
          margin: 0;
          min-height: 55px;
          line-height: 55px;
        }
        .ajouter-panier {
          width: 50%;

          &.block-addbasket {
            width: 100%;
          }
          .prix {
            padding: 0;
            width: 200%;
            margin-left: -100%;
            margin-top: 15px;
            margin-bottom: 15px;
            border: 1px solid;
            .prixprod, .oldprix {
              margin: 0;
              height: 53px;
              line-height: 53px;
            }
            .oldprix {
              text-align: right;
              vertical-align: top;
              margin-right: 3px;
            }
            .prixprod {
              text-align: left;
              top: 0;
            }
            .triangle-ficheprod-second {
              display: block !important;
              width: 50%;
              float: right;
              margin: 0;
              position: static;

              span.remise-produit {
                width: 100%;
                height: 53px;
              }
            }
          }
        }
        .pro-pannier ~ p {
          float: none;
          width: 100%;
        }
        .button {
          display: inline-block;
          &.addbasket {
            margin: 0;;
          }
        }
        .details-ficheprod {
          text-align: center;
          display: none;
        }
      }
      .remise-produit {
        position: relative;
        font-size: 30px;
        padding: 5px 15px;
        border-radius: 0;
      }
    }
  }
  &.template_fiche_produit_3 {
    #fiche-produit {
      h1, .h1 {
        span {
          display: block;
          padding: 15px;
        }
      }
      .wrap-images {
        .triangle-ficheprod {
          //display: none;
          .bx-wrapper .bx-controls-direction a {
            &.bx-next::before, &.bx-prev::before {
              line-height: 54px;
            }
          }
        }
      }
      .bx-controls-direction a {
        line-height: 54px;
      }
      .wrap-description {
        padding-left: 0;
        .container-etiquette {
          //display: none;
        }
        .declinaison {
          .declinaison-attribute-group .radio_color, .product_options-group .radio_color {
            margin: 5px;
          }
        }
        .bloc-quantite {
          margin-left: 0;
          box-sizing: border-box;
          padding: 10px 10px;
          label {
            font-size: 15px;
            margin-right: 5px;
          }
          .notification_product {
            height: auto;
            width: 100%;
          }
        }
        .ajouter-panier {
          box-sizing: border-box;
          margin-left: 0;
          padding-top: 0;
        }
        .declinaisons-achat .pro-pannier .ajouter-panier .addbasket {
          width: 100%;
        }
        .in-onglet.contents-tabs {
          margin: 5px 0;
          padding: 0 15px;
          .note-produit {
            margin: 10px 0;
          }
        }
      }

      .declinaison {
        margin-bottom: 0;
      }

      .declinaisons-achat {
        .pro-pannier {
          display: flex;
          flex-direction: column;
        }
        .bloc-quantite {
          width: 100%;
          margin-bottom: 0;
          text-align: left;
        }
        p.prix {
          padding: 0;
          width: 100%;
          margin-top: 28px;
          margin-bottom: 15px;
          border: 1px solid;
          text-align: center;
          .prixprod, .oldprix {
            margin: 0;
            height: 53px;
            line-height: 53px;
            width: auto;
          }
          .oldprix {
            text-align: right;
            vertical-align: top;
            margin: 0 3px 0 0;
            display: inline-block;
          }
          .prixprod {
            text-align: left;
            top: 0;
            display: inline-block;
          }
          .triangle-ficheprod-second {
            display: block;
            width: 50%;
            float: right;
            margin: 0;
            position: static;
            span.remise-produit {
              width: 100%;
              height: 53px;
              font-size: 25px;
              text-align: center;
              line-height: 53px;
              padding: 0;
            }
          }
        }
        .ajouter-panier {
          width: 100%;
          &::before {
            display: none;
          }
          .addbasket {
            line-height: normal;
            padding: 15px 10px;
          }

        }
      }
      .content-tab {
        overflow: hidden;
      }
      .remise-produit {
        position: relative;
        right: auto;
        font-size: 30px;
        padding: 5px 15px;
        display: inline-block;
        height: auto;
        width: auto;
        border-radius: 0;
      }
      .bx-wrapper .bx-controls-direction a {
        top: 50%;
        border: 3px solid;

        &.bx-prev {
          left: 30px;
        }
      }
    }
  }
}

/*********************************************************/
/********************* GALERIE PHOTO *********************/
/*********************************************************/

.template_album .galerie {
  overflow: hidden;
  .addcommentaires {
    border-width: 0;
    border-radius: 0;
    left: auto;
    top: auto;
    bottom: 0;
    right: 100%;
    display: block;
    transition: all .2s linear;
  }
  .mask.just-comment {
    opacity: 0;
    width: 100%;
  }
  .commentP, &:hover .commentP, &:focus .commentP {
    display: block;
    p {
      font-size: 16px;
      font-weight: bold;
      line-height: 16.3px;
      padding-bottom: 13px;
      display: inline-block;
      text-transform: uppercase;
      text-align: left;
      position: relative;
      transition: all .2s linear;
      bottom: auto;
      top: 0;
      left: 100%;
      max-width: 100%;
      box-sizing: border-box;
      padding-left: 10px;

      &:before {
        content: "";
        width: 18px;
        height: 2px;
        display: inline-block;
        position: absolute;
        bottom: 0;
      }
    }
  }
  &:hover, &:focus {
    box-shadow: none;
    transform: scaleX(1);
    .center-img .mask {
      opacity: .5;
    }
    .commentP p {
      left: 0;
    }
    .addcommentaires {
      right: 15px;
      bottom: 10px;
    }
  }
}

.mask a.zoom {
  display: block;
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  margin: auto;
  height: 100%;
}

.template_album {
  &.template_album_1 {
    .galerie {
      .commentP {
        //height: 100%;
        padding-top: 45px;
        top: 0;
        bottom: auto;
      }
      .addcommentaires {
        z-index: 10;
      }
    }
  }
  &.template_album_3 {
    .galerie {
      .bloc-cache-overflow {
        .bloc-titre-galerie p {
          margin-top: 0;
          padding-top: 45px;
          left: 100%;
        }
      }
      .mask.animMask {
        display: none;
      }
      &:hover {
        .bloc-cache-overflow .bloc-titre-galerie p {
          left: 0;
        }
      }
    }
  }
  &.template_album_4, &.template_album_5 {
    .galerie {
      .addcommentaires {
        top: 15px;
        bottom: auto;
      }
      .commentP {
        padding: 15px 0;
        .mask {
          opacity: 1;
        }

        p {
          font-size: 16px;
          font-weight: bold;
          line-height: 16.3px;
          display: block;
          text-transform: uppercase;
          text-align: left;
          bottom: auto;
          padding: 10px 25px;
          left: 0;

          &:empty {
            display: none;
          }

          &:before {
            content: "";
            width: 18px;
            height: 2px;
            display: inline-block;
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }
}

/**************************************************/
/********************* SLIDER *********************/
/**************************************************/

.banner_diaporama .bx-controls.bx-has-controls-direction {
  top: 0;
}

.bx-wrapper {
  .bx-viewport {
    left: 0;
  }
  @at-root .slide-text a, & .bx-controls-direction a {
    position: absolute;
    right: 30px;
    left: auto;
    top: 30px;
    outline: 0;
    width: 60px;
    height: 60px;
    text-align: center;
    text-indent: -9999px;
    line-height: normal;
    z-index: 50;
    transition: all .2s linear;
    margin-top: 0;

    &.addcommentaires {
      padding: 0;
      text-indent: 0;
      transition: none;
      height: 35px;
      right: auto;
      left: auto;
      top: auto;
      bottom: auto;
      line-height: 35px;
      margin: 0;
    }

    &.slider-see-more {
      text-indent: 0;
      right: 90px;
      width: auto;
      line-height: initial;
      padding: 19px 10px;
      min-height: 60px;
      height: auto;
      border: 2px solid;
    }

    &.bx-next::before, &.bx-prev::before {
      content: '\f105';
      display: block;
      font-size: 30px;
      text-indent: 0;
      font-family: Fontawesome, sans-serif;
    }

    &.bx-prev, &.bx-next {
      overflow: hidden;
    }

    &.bx-prev {
      &::before {
        content: '\f104';
      }
    }
  }
  .bx-controls-direction a {
    line-height: 60px;
  }
}

.slide-text a {
  &.bx-next::before, &.bx-prev::before {
    line-height: 54px;
  }
}

.accueil_diaporama_template {
  .slide {
    > div, > li {

    }
    .slide-text {
      background: rgba(0, 0, 0, 0.8);
      width: 80%;
      height: auto;
      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0;
      top: auto;
      display: block;
      box-sizing: border-box;
      margin: 0 auto;
      padding: 20px;
      text-align: center;
      &.nocomment.nolink {
        width: 60px;
        left: auto;
        right: 10%;
      }
      .content-slide-text p {
        position: relative;
        left: auto;
        bottom: auto;
        font-size: 25px;
        font-weight: bold;
        margin: 0;
      }
      .addcommentaires {
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        width: 50px;
        top: 0;
        position: absolute;
        right: 0;
        display: block;
        background: transparent;
        padding: 0;
      }
      a.slider-see-more.button {
        display: inline-block;
        bottom: auto;
        top: auto;
        right: auto;
        position: relative;
        min-width: 150px;
        margin: 0 auto;
        border: 2px solid;

      }
      > p {
        right: 0;
        padding: 30px;
        display: inline-block;
        font-size: 23px;
        line-height: 1.1;
        text-transform: uppercase;
        background: none;
        text-align: center;
        position: relative;
        font-weight: bold;
        margin: 0 auto;
        left: 0;
        bottom: auto;
        width: 100%;
      }
      > a.slider-see-more {
        display: inline-block;
        margin: 0 auto;
        top: 0;
        left: 0;
        position: relative;
        right: 0;
        background: none;
        bottom: 0;
        border: 2px solid;

        &:hover {
          border: 2px solid;
        }
      }
    }
  }
  .bx-controls-direction {
    .bx-prev, .bx-next {
      display: block;
      font-size: 45px;
      position: absolute;
      top: 40%;
      opacity: 0;
      width: 60px;
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
      border: 3px solid;
      &::before {
        height: 54px;
        line-height: 54px;
      }
    }
    .bx-prev {
      left: 60px;
    }
    .bx-next {
      right: 60px;
    }
  }
  &:hover {
    .bx-controls-direction {
      .bx-prev, .bx-next {
        opacity: 1;
        display: block;
      }
    }
  }

  &.accueil_diaporama_template_1 {
    .slide {
      .slide-text {
        width: 70%;
      }
    }
  }
  &.accueil_diaporama_template_2 {
    margin-bottom: 0;
    .slide {
      .slide-text {
        width: 100%;
        &.nocomment.nolink {
          right: 0;
        }
      }
    }
  }
  &.accueil_diaporama_template_4,
  &.accueil_diaporama_template_6 {
    margin-top: 0;
    .slide {
      position: relative;
      .center-img-slider {
        margin: 0;
        font-size: 0;
        line-height: 0;
      }
      .slide-text {
        width: 100%;
        bottom: 0;
        padding: 26px;
        opacity: 0;
        transition: all .2s linear;

        &.nocomment.nolink {
          min-width: 78px;
          left: auto;
          right: 0;
        }
        .content-slide-text {
          text-align: center;
          p {
            position: relative;
            font-size: 20px;
            font-weight: bold;
          }
        }
        a {
          position: relative;
          &.addcommentaires {
            position: absolute;
            bottom: auto;
            top: 50%;
            margin-top: -25px;
            right: 10px;
            left: auto;
            text-indent: 0;
            :hover {
              cursor: pointer;
            }
          }
          &.slider-see-more {
            position: relative;
            margin: 0 auto;
            left: auto;
            top: auto;
            display: inline-block;
            border: 2px solid;
            right: auto;
            bottom: auto;
            :hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  &.accueil_diaporama_template_5 {
    .slide {
      .slide-text {
        &.nocomment.nolink {
          width: 60px;
        }
        .content-slide-text {
          .slider-see-more {
            min-height: 0;
          }
        }
      }
    }
  }
  &.accueil_diaporama_template_1,
  &.accueil_diaporama_template_3,
  &.accueil_diaporama_template_5,
  &.accueil_diaporama_template_6 {
    width: $largeur_contenu;
    margin: 0 auto 20px;
  }
  &.accueil_diaporama_template_1,
  &.accueil_diaporama_template_2,
  &.accueil_diaporama_template_3 {
    .bx-wrapper {
      margin-bottom: 0;
      .bx-viewport {
        max-width: 100%;
      }
      .bx-pager {
        bottom: 15px;
      }
    }
  }
}

/******************************************************/
/********************* LIVRE D'OR *********************/
/******************************************************/

.template_livreor_1 .message_lo {
  padding: 20px;
  border: 1px solid;
}

.button.addmsglo {
  margin: 10px auto;
  width: 50%;
  display: block;
  text-align: center;
}

/******************************************************/
/********************** CONTACT ***********************/
/******************************************************/

.template_contact_3 .row form .form-group textarea,
.template_contact_3 .row form .form-group input:not([type='file']) {
  border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

/**************************************************/
/********************* FOOTER *********************/
/**************************************************/

footer {
  padding: 60px 20px 20px;
  text-align: center;
  clear: both;
  width: 100%;
}

/**************************************************/
/********************* PANIER *********************/
/**************************************************/

/*Template Panier */
.template-panier {
  &.template-panier-2,
  &.template-panier-3 {
    border: none;
  }
  &.template-panier-2 {
    #panier .ajout_panier a {
      line-height: 30px;
    }
  }
  &.template-panier-4 {
    position: absolute;
    right: 0;
    top: 0;
    min-height: 0;
    height: 55px;
    .connexion {
      a {
        padding: 0;
      }
    }
    .connexion, #panier {
      height: 55px;
    }
    #panier #header-panier, .connexion {
      line-height: 55px;
    }
    #header-panier {
      a.paniertoggle {
        top: 8px;
      }
      .count-live {
        top: 15px;
        left: 22px;
      }
    }
  }
}

.cmonsite-panier-4 #nav-secondary {
  > ul {
    float: none;
  }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
  .side-tabs.etapes,
  div#resume .block-recap-panier,
  div#resume div.next-step,
  div#information #connexion-inscription,
  div#information div.next-step,
  #infoscommande,
  div#finish .block-info-payment,
  div#finish div.next-step,
  div#resume h4,
  div#finish h4,
  .message-commande {
    width: 95%;
  }

  .logo-carrier-none {
    width: auto;
  }

  h4 {
    margin: 0;
  }

  div#information .list_address {
    margin: 0 0 20px;
  }
}

/*-----------------------------
    Boutons profil
-------------------------------*/

#order .commandes td {
  padding: 10px 0;
  border-top: 1px solid rgba(128, 128, 128, .1);
}

#order .commandes-details {
  > td > div {
    border-bottom: none;
  }
  .infosclient.s6 {
    &, &:first-child {
      width: 100%;
      margin: 10px 0 0;
    }
  }
}

/*********************************************************/
/********************* MEDIA QUERIES *********************/
/*********************************************************/

@media #{$large-and-down} {
  $largeur_fenetre_grand: $medium-screen - 80px;
  $largeur_contenu: $largeur_fenetre_grand - 62px;

  #wrapper,
  .accueil_diaporama_template.accueil_diaporama_template_1,
  .accueil_diaporama_template.accueil_diaporama_template_5 {
    max-width: $largeur_contenu;
    width: 100%;
  }
  #headercontent {
    max-width: 100%;
    width: 100%;
    padding-right: 0;
    margin-bottom: 15px;
  }
}

@media #{$medium-and-down} {
  $largeur_contenu: 95%;

  /* Content */

  #wrapper, footer {
    padding: 15px;
  }

  #wrapper,
  .accueil_diaporama_template.accueil_diaporama_template_1,
  .accueil_diaporama_template.accueil_diaporama_template_5 {
    width: 100%;
    max-width: $largeur_contenu;
  }
  #blocContent {
    min-height: 40px;
  }

  /* Header */

  #headercontent {
    height: 100%;

    #logo {
      position: relative !important;
      text-align: center !important;
      left: 0 !important;
      right: auto !important;
      top: 0 !important;
      bottom: auto !important;
      margin: 0 auto;
      padding: 15px 0;
      display: block;
      z-index: 0;
      width: auto !important;
      max-height: 100px;
      float: none;
      max-width: 200px !important;
      height: auto !important;

      img {
        max-height: 70px;
        width: auto;
        height: auto;
      }

    }
    div.container-slogan {
      height: 100%;
      display: block;
      font-size: 0;
      line-height: 0;
      width: auto;

      &::before {
        content: '';
        height: 100%;
        width: 0;
        vertical-align: middle;
        display: inline-block;
      }
    }
    .slogan_p {
      position: static !important;
      width: 100% !important;
      text-align: center !important;
      vertical-align: middle;
      display: inline-block !important;
      font-size: 22px !important;
      line-height: initial !important;
    }
  }

  /* Menu */

  .header-conteneur {
    .menu.navigation_p {
      position: absolute;
      left: 0;
      right: 0;
    }
    #blocContent {
      padding-top: 62px;
    }
  }

  .menu.navigation_p {
    position: relative;
  }

  #nav-secondary {
    display: none;
  }

  .cmonsite-panier-4 #nav-secondary {
    display: block;
    width: 100%;
    &::after {
      content: '';
      display: block;
      clear: both;
    }
    ul {
      display: none;
    }
  }
  .template-panier.template-panier-4 {
    position: relative;
    float: right;
    #header-panier .count-live {
      left: 22px;
      top: 15px;
    }
  }

  #nav-principal {
    width: 100%;
    padding: 0;
    position: static;
    z-index: 10;
    > ul {
      top: 0;
      width: 100%;
      position: relative;
      display: none;
      &::before, &::after {
        content: '';
        display: block;
        clear: both;
      }

      li {
        height: auto;

        &.hasDropdown > a::after {
          display: none;
        }
      }

      ul {
        position: relative;
        top: 0;
        left: 0 !important;
        max-width: 100%;
        transform-origin: left center;
        transform: scaleX(1);
        transition-property: none;
        margin-top: 0;
        opacity: 1;

        li {
          border-right-width: 0;
          border-left-width: 0;
          &:last-child {
            border-bottom-width: 0;
          }
        }
      }
    }
    li {
      width: 100%;
      display: block;
      &.sousmenu_ouvert > a::after {
        left: 62px !important;
        top: 98%;
        margin-left: -36px;
        transform: rotate(90deg);
        opacity: 1 !important;
      }
      &.sousmenu_ouvert > .dropDown::before {
        content: '\f0d8';
      }
      a {
        font-size: 15px;
        text-align: center;
      }
    }
  }

  #nav-principal a.menu_burger {
    text-align: center;
    float: right;
    position: relative;
    width: 62px;
    height: 62px;
    line-height: 62px;
    right: 0;
    top: 0;
    font-size: 25px;
    display: inline-block;
    padding: 0 !important;
    z-index: 9999;
    &::before {
      content: '\f0c9';
      display: inline-block;
      font-family: 'FontAwesome', sans-serif;
    }
  }

  .dropDown {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: bold;
    display: block;
    height: 40px;
    width: 60px;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    font-size: 1.2rem;
    &::before {
      font-family: FontAwesome, sans-serif;
      content: "\f0d7";
    }
  }

  #bloc-fil-title {
    width: auto;
  }

  /* Diaporama accueil */

  .accueil_diaporama_template {
    .bx-controls-direction {
      display: none;
    }
    .slide .button_slider {
      border: 2px solid;
      margin: 2% 5%;
    }
    .slide > div, .slide > li {
      // Bouton chow / hide les texte dans le slider
      .button_slider {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        font-size: 25px;
        font-weight: bold;
        font-family: 'Montserrat', 'Arial', sans-serif;
        transition: all .2s linear;
        margin-top: 0;
        line-height: 55px;
        height: 55px;
        width: 55px;
        box-sizing: border-box;
        text-align: center;
        z-index: 2;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
        &:hover {
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        }

        ~ .slide-text {
          padding: 0;
          .addcommentaires {
            display: none;
          }
        }
      }
      .slide-text {
        opacity: 1;
        width: 90%;
        height: 90%;
        max-width: 0;
        max-height: 0;
        min-width: 0;
        min-height: 0;
        transform: none;
        margin: 0;
        top: 5%;
        left: 5%;
        display: block;
        vertical-align: middle;
        line-height: 0;
        overflow: hidden;
        transition: all .2s linear;
        font-size: 0;
        &::before {
          display: inline-block;
          height: 100%;
          vertical-align: middle;
          content: '';
          width: 0;
        }
        .content-slide-text {
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          line-height: initial;
          width: 100%;
          p {
            font-size: 13px;
          }
        }
        p, a.slider-see-more {
          display: inline-block;
          width: 100%;
          line-height: initial;
        }
        a.addcommentaires {
          width: 51px;
          right: 0;
          font-size: 16px;
        }
        a.slider-see-more.button {
          min-width: 0;
          padding: 10px;
          min-height: 0;
        }
      }

      .button_slider.slide-ouvert {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        ~ .slide-text {
          min-width: 90%;
          max-height: 450px;
          padding: 0 20px;
          .addcommentaires {
            display: block;
          }
        }
      }
    }
  }

  /* Produits */

  .template_boutique_accueil {
    &.accueil_boutique_template_1 {
      $largeurFleches: 45px;
      $margin: 20px;
      $nbrProduit: 2;
      @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
      .bx-wrapper .bx-controls-direction a {
        margin-top: 0;
      }
    }
    &.accueil_boutique_template_2 {
      margin: 10px;
    }
  }
  .template_boutique {
    .produits {

    }

    &.template_boutique_3 {
      .produits {
        .productContentWrap {
          width: 100%;
        }
        .prix {
          margin: 0;
        }
      }
    }
  }

  /* Fiche produits */

  .template_fiche_produit {
    #fiche-produit {
      .top_fiche {
        display: block !important;
        .wrap-description .row .col.s12 h3,
        .wrap-description .row .col.s12 .h3 {
          width: 100%;
        }
      }
      .wrap-details {
        padding: 15px;
      }
      .wrap-description {
        .ajouter-panier .triangle-ficheprod ~ .prix {
          .oldprix, .prixprod {
            line-height: 0;
          }
        }
      }
      .wrap-images,
      .wrap-description,
      #savoirplus.wrap-details.bloc-description-detaille {
        display: block !important;
        width: 100% !important;
        padding: 0 15px;
        margin-top: 15px;
      }
      .form-group {
        padding: 0 10px 0 0;
      }
      .form-control {
        margin: 3px 0;
      }
    }
    &.template_fiche_produit_2 {
      #fiche-produit {
        .wrap-images {
          .grande-image a.sliderImageFicheProd, .grande-image #sliderImagePreviews {
            width: 100%;
          }
          .grande-image #sliderImagePreviews a {
            width: 15%;
          }
        }
        .container-etiquette .produit_etiquette {
          &, &:hover {
            left: auto;
            position: relative !important;
            overflow: visible;
            &::before {
              opacity: 1;
              left: 50px;
              right: auto;
            }
          }
        }
      }
    }
    &.template_fiche_produit_3 {
      #fiche-produit {
        .wrap-description {
          padding-right: 0;
          position: static;
          .container-etiquette {
            top: -130px;
            position: absolute;
            right: 0;
            .produit_etiquette {
              width: 50px !important;
              display: block;
              max-width: 50px !important;

            }
          }
          .declinaison {
            padding: 0;
          }
          .declinaison.row {

          }
          .declinaison-attribute-group, .product_options-group {
            margin-bottom: 0;
          }
          .bloc-quantite {

          }
          p.prix {
            top: -75px;
          }
          .ajouter-panier {

            .addbasket {
              margin: 0;
              display: inline-block;
            }
          }
          .pro-pannier {

          }
          .details-descr-com {
            .side-tabs {
              width: 100%;
              a {
                width: 31%;
                float: left;
                margin: 1.16% !important;
                min-height: 75px;
                font-size: 10px;
                &::before {
                  display: block;
                  font-size: 12px;
                  margin-right: 0;
                }
              }
            }
            .in-onglet {
              width: 100%;
              padding: 0;
            }
          }
        }
      }
    }
  }

  /* Template 2 Panier responsive */
  .template-panier {
    &.template-panier-2 {
      top: 62px;
      border: none;
      #header-panier, .bloc-connexion-panier {
        font-size: 0;
        text-align: center;
        line-height: 40px;
        top: -62px;
        height: 62px;
        width: 62px;
        right: 0;
        position: absolute;
        .count-live {
          font-size: 10px;
          left: 35px;
          top: 15px;
        }
        .fa-shopping-cart {
          font-size: 22px;
          margin-right: 0;
        }
      }
      #paniercontent {
        top: 100%;
        left: auto;
        right: 0;
      }
      .paniertoggle {
        font-size: 0;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .fa {
          font-size: 0;
        }
      }
    }
  }

  /*Suppression du bloc de recherche a droite*/
  #block-flottant-search {
    display: none;
  }
  #minisite-sidebar {
    display: none;
  }

}

[lang="en"] {
  .template_fiche_produit #fiche-produit .wrap-description .declinaisons-achat .pro-pannier .ajouter-panier .addbasket {
    font-size: 11px;
  }
}

@media #{$medium-and-up}{
  .menu_burger {
    display: none;
  }
  .button_galerie {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  #fiche-produit .wrap-description .col.s4.block-fiche-brand a {
    display: block;
    padding: 10px 0;
  }
}

@media #{$small-and-down} {
  /* Tableaux responsive */
  @import '../../design/helper/responsive/mobile/mobile';

  /* Diaporama */

  /* Produits */

  .produit_etiquette {
    overflow: visible;
    &::before {
      opacity: 1;
      right: 25px;
    }
  }
  .template_boutique_accueil {
    &.accueil_boutique_template_1 {
      $largeurFleches: 45px;
      $margin: 0px;
      $nbrProduit: 1;
      @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
    }
    .bx-wrapper .bx-controls-direction a.bx-next, .bx-wrapper .bx-controls-direction a.bx-prev {
      left: auto;
      right: 0;
      &::before {
        line-height: 40px;
      }
    }
  }
  .template_boutique {
    &.template_boutique_5 {
      .produits {
        .container-etiquette .produit_etiquette {
          position: absolute;
          &::before {
            padding: 0 35px 0 10px;
            right: 25px;
            left: auto;
          }
        }
      }
    }
  }
  .template_boutique_all {
    .produits {
      .remise ~ .prix, .remise ~ .productContentWrap .prix {
        border-right: 1px solid;
        height: 55px;
        line-height: normal;
        padding: 0;
        margin: 0 0 20px;
      }
      .attr:empty {
        display: none;
      }
      .productImageWrap > .zoom {
        display: block;
        opacity: 0;
        bottom: auto;
      }
      .zoom {
        margin: 0;
        opacity: 0;
        &::before, i {
          line-height: 70px;
          height: 70px;
          width: 70px;
          text-align: center;
          font-size: 20px;
        }
      }
      .addbasket {
        margin: 0;
        opacity: 1;
        &::before, i {
          line-height: 70px;
          height: 70px;
          width: 70px;
          text-align: center;
          font-size: 20px;
        }
      }
      .prix {
        width: 100%;
        padding: 0;
      }
      .addbasket {
        margin: 15px 0;
      }
    }
  }

  /* Fiche produits */

  .template_fiche_produit {
    #fiche-produit {
      .wrap-details {
        .details {
          text-align: justify;
        }
      }
      .wrap-description {
        width: 100%;
        .bloc-quantite {
          width: 50%;
          text-align: left;
          label {
            font-size: 14px;
          }
        }
      }

      .declinaison {
        margin-bottom: 0;
      }

      .ajouter-panier {
        &.block-price.without-promotion {
          width: 50%;
          margin-bottom: 0;
          .prix {
            width: 100%;
            padding: 0;
          }
        }

        &.block-addbasket {
          width: 100%;
        }
      }
    }
    &.template_fiche_produit_2 #fiche-produit {
      .wrap-description {
        position: static;
      }
      .top_fiche .wrap-description .container-etiquette {
        position: absolute;
        top: 0;
      }
    }
  }

  /* Galerie */
  .button_galerie {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9999;
    width: 45px;
    text-align: center;
  }
  .template_album {
    .galerie {
      margin-bottom: 15px;
      .bloc-cache-overflow {
        overflow: hidden;
        max-height: 0;
        padding: 0;
        height: 100%;
        position: absolute;
        top: 0;
      }
      &:hover {
        .bloc-cache-overflow {
          min-height: 0;
        }
        .addcommentaires {
          right: 100%;
        }
        .mask {
          opacity: 0;
        }
      }
      &.hover {
        .mask {
          max-height: 100%;
          opacity: 1;
        }
        .bloc-cache-overflow {
          max-height: 100%;
          > p {
            margin-top: 35px;
          }
        }
        .addcommentaires {
          right: 15px;
        }
        .bloc-titre-galerie p {
          left: 0;
          font-size: 16px;
          font-weight: bold;
          line-height: 16.3px;
          padding-bottom: 13px;
          display: block;
          text-transform: uppercase;
          text-align: left;
          position: absolute;
          transition: all .2s linear;
          bottom: auto;
          top: 0;
        }
      }
    }
    &.template_album_3 .galerie .mask {
      max-height: 0;
    }
  }
}